.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.log-label{color: #fff !important; font-size: 16px !important;}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.white{
  color: #fff !important;
}
.bg-blue-banner{ background-image: url('https://cdn.virtueanalytics.com/cache/thefinlitproject-banner-bg.jpg'); background-size: cover; background-repeat: no-repeat;}
.form1 label, .MuiFormLabel-root{font-size: 17px !important; color: #0f365f !important;}
.radio-row{display: flex !important;}
.input-fm1 input{width :90% !important; border: 1px solid #afaeae !important; box-shadow: 1px 2px 1px #ddd; border-radius:6px !important; margin-top: 6px; }
.option-col .MuiButtonBase-root svg{fill:  #0f365f  !important;}
.fmresult{font-size:18px !important;  color:#333 !important; font-weight: normal !important; }
.in-wdth{width: 200px;     font-weight: 600 !important; }
.login-lb{color: #ccc !important; margin-bottom: 6px;}
.in-wdth input{font-size:36px !important;  width: 100% !important; text-align: center !important;  font-weight: 500 !important;   padding: 16px 14px !important; margin-top: 14px !important;}

